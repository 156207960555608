import classNames from 'classnames'
import Img from 'gatsby-image'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { graphql, Link } from 'gatsby'

import styles from './styles.module.css'

class About extends Component {
    render() {
        const { content } = this.props

        return (
            <div
                className={styles.aboutSection}
            >
                <div className={styles.imageSection}>
                    <div className={styles.imageSubsection}
                        data-aos="fade-up"
                        data-aos-duration="1200">
                        <Img
                            critical
                            className={styles.image}
                            fluid={content.image.value.childImageSharp.fluid}
                            alt="Header picture"
                            onStartLoad={this.props.loading.register}
                            onLoad={this.props.loading.notify}
                        />
                    </div>
                </div>
                <div className={styles.textSection}>
                    <div className={styles.textSubsection}>
                        <div
                            className={styles.titleContainer}
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <div className={styles.title}
                                dangerouslySetInnerHTML={{
                                    __html: content.primary.value.toUpperCase(),
                                }}
                            />
                            <div className={styles.secondaryTitle}
                                dangerouslySetInnerHTML={{
                                    __html: content.secondary.value.toUpperCase(),
                                }}
                            />
                            <div className={styles.lineSection}>
                                <div className={classNames(styles.line, styles.black, styles.blackBackground)} />
                            </div>
                            <div className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: content.description.value,
                                }}
                            />
                            <div className={styles.lineSection}>
                                <div className={classNames(styles.line, styles.black, styles.blackBackground)} />
                                <div className={classNames(styles.button, styles.linkBlack)}>
                                    <Link to="/about">
                                        <FormattedMessage id ="none" defaultMessage={'Read more'} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About

export const query = graphql`
  fragment About on CockpitAbout {
        primary {
            value
        }
        secondary {
            value
        }
        description {
            value
        }
        image {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
`
